<template>
  <div v-if="this.result.valueLine.c_type == 'DIAMETER'">
    <v-divider></v-divider>
    <v-row>
      <v-col class="mb-5" id="custom_cer_switch_container">
        <v-switch
          hide-details
          v-model="enableCer"
          label="Custom CER"
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["result", "valueLine"],
  computed: {
    enableCer: {
      get() {
        let active = false;
        if (!this.empty(this.result.valueLine.templateId)) {
          active = true;
        }
        return active;
      },
      set(newVal) {
        let valueLine = { ...this.valueLine };
        if (newVal) {
          let key = Object.keys(
            this.result["fields"]["templateId"].associatedOption
          )[0];
          if (typeof key == "undefined") {
            key = "empty";
          }
          this.$set(valueLine, "templateId", key);
        } else {
          this.$delete(valueLine, "templateId");
        }
        // console.log(
        //   "changeing value",
        //   this.result["fields"]["templateId"].value
        // );
        this.$set(this.result, "valueLine", valueLine);
         this.$store.commit("dialogResultChanged", this.result);
      },
    },
  },
};

// {
//   let $fieldsObject = $("#{$id}").closest(".fields");
//   toggleTemplateSelector($fieldsObject);

//   $(function () {
//     $fieldsObject.on("click", ".templateButton", function () {
//       let $templateButton = $(this);
//       toggleDiameterCerTemplate($templateButton, false);
//     });
//   });
// }
// function toggleDiameterCerTemplate($templateButton, hide) {
//   let $fieldsObject = $templateButton.closest(".fields");
//   let $templateline = $fieldsObject.find(".templateline");
//   let $templateIdSelector = $templateline.find(".templateIdSelector");
//   if ($templateButton.hasClass("inactivePrettyButton") && hide === false) {
//     $templateButton
//       .addClass("activePrettyButton")
//       .removeClass("inactivePrettyButton");
//     $templateline.show();
//     $templateIdSelector.prop("disabled", false);
//   } else {
//     $templateButton
//       .addClass("inactivePrettyButton")
//       .removeClass("activePrettyButton");
//     $templateline.hide();
//     $templateIdSelector.prop("disabled", true);
//   }
//   resizeForm($fieldsObject);
// }
// function toggleTemplateSelector($fieldsObject) {
//   let $templateline = $fieldsObject.find(".templateline");
//   let $templateButton = $fieldsObject.find(".templateButton");
//   let $templateIdSelector = $templateline.find(".templateIdSelector");
//   if ($templateButton.hasClass("inactivePrettyButton")) {
//     $templateline.hide();
//     $templateIdSelector.prop("disabled", true);
//   } else {
//     $templateline.show();
//     $templateIdSelector.prop("disabled", false);
//   }
// }
//
</script>


